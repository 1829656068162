import { IonCol, IonItem, IonSpinner, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../services/app-context.service';
import User from '../../types/user.type';
import UserForm from './Form.component';
import { apiBaseUrl } from '../../configs/app';
import Role from '../../types/user-role.type';

type Props = {
  user:User
  roles:Role[]
  onUpdate:any
}

const EditorPanel: React.FC<Props> = ({user,roles,onUpdate}) => {
  const store = useAppContext() as any
  if(store.innerWidth < 1240){
    return null
  }
  return <IonCol size='4'>
    <IonItem color={user.id ? "medium":"light"}>
      <IonText><b>{user.id ? `Edit User #${user.id}`:'Add New User'}</b></IonText>
    </IonItem>
    <UserForm user={user} roles={roles} onUpdate={onUpdate}/>
  </IonCol>

};
export default EditorPanel
