import { IonButton, IonInput, IonItem, IonText } from '@ionic/react';

const SearchBar: React.FC = () => {

    return <>
        <IonItem className="search-bar">
            <IonInput placeholder='I am looking for...'/>
            <IonButton slot='end' size='default'><IonText color="light">Search</IonText></IonButton>
        </IonItem>
    </>
};

export default SearchBar;
