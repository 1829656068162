import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonList, IonTitle, IonSearchbar, IonToolbar } from '@ionic/react';

import Airline from '../../types/airline.type';


interface TypeaheadProps {
  items: Airline[];
  selectedItem: Airline;
  title?: string;
  onSelectionCancel?: () => void;
  onSelectionChange?: (item: Airline) => void;
}

function AirelineSelectorModal(props: TypeaheadProps) {
  const [filteredItems, setFilteredItems] = useState<Airline[]>([]);
  const [workingSelectedValues, setWorkingSelectedValues] = useState<Airline>(props.selectedItem);


  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  }

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValues);
    }
  }

  const searchbarInput = (ev: any) => {
    filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery: string | null | undefined) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      const newFilteredItems = props.items.filter(item => {
        return item.iata.toLowerCase().includes(normalizedQuery);
      })
      console.log(normalizedQuery,newFilteredItems.length)
      setFilteredItems([...newFilteredItems]);
    }
  }

  const onSelect = (item: Airline) => {

    const { onSelectionChange } = props;
    onSelectionChange && onSelectionChange(item);
  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="medium">
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={cancelChanges}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput} placeholder='Search by airline code. Like : AA'></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
          {filteredItems.map((item,index) => (
            <IonItem key={index}>
              <IonLabel onClick={()=>onSelect(item)}>{item.iata} - {item.name}</IonLabel>
            </IonItem>
          ))}
      </IonContent>
    </>
  );
}
export default AirelineSelectorModal;
