import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from '../pages/auth/Login';
import { appSubDir } from '../configs/app';
import Home from '../pages/Home';
import Contact from '../pages/Contact';


const PublicRoutes: React.FC = () => (
  <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={appSubDir}>
          <Home />
        </Route>
        <Route exact path={appSubDir+"contact"}>
          <Contact />
        </Route>
        <Route>
          <Redirect to={appSubDir} />
        </Route>
      </IonRouterOutlet>
  </IonReactRouter>
);

export default PublicRoutes;
