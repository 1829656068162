import { useEffect, useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { apiBaseUrl } from '../../configs/app';
import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import Category from '../../types/category.type';
import { arrowForward } from 'ionicons/icons';
const localStorageKey = 'categories-and-subcategories'
const slideOpts = {
    autoplay: {
        pauseOnMouseEnter: true,
        delay: 4000
    },
    pagination: false,
    direction: "vertical",
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 6,
            spaceBetween: 2
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 6,
            spaceBetween: 2
        },
        1024: {
            slidesPerView: 6,
            spaceBetween: 0
        },
        1440: {
            slidesPerView: 9,
            spaceBetween: 0
        }
    }
} as any;
const AllCategories: React.FC = () => {
    const localData = window.localStorage.getItem(localStorageKey)
    const [items, setItems] = useState((localData ? JSON.parse(localData).categories : []) as Category[])
    function fetchData() {
        const requestOptions = {
            method: 'POST',
            cache: "no-store",
            redirect: 'follow'
        };
        const url = apiBaseUrl + 'products/categories-and-subcategories'
        fetch(url, requestOptions as RequestInit).then((res) => res.json())
            .then((res) => {
                // console.log(res)
                if (res.success) {
                    window.localStorage.setItem(localStorageKey, JSON.stringify(res))
                    setItems(res.categories)
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [])

    return <>
        {items.length > 0 && <Swiper {...slideOpts} modules={[Autoplay]}>
            {items.map((item, index) => <SwiperSlide key={index}>
                <IonItem button={true}>
                    <IonLabel>{item.title}</IonLabel>
                    <IonButton slot='end' fill='clear'><IonIcon icon={arrowForward} /></IonButton>
                </IonItem>
            </SwiperSlide>)}
        </Swiper>}
    </>
};

export default AllCategories;
