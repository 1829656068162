import { Redirect, Route } from 'react-router-dom';
import {
  IonMenu,
  IonPage,
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from '../pages/Home';
import Menu from '../components/Menu';
import { appSubDir } from '../configs/app';
import Reservations from '../pages/Reservations';
import Account from '../pages/Account';
import UsersPage from '../pages/users/UsersPage';
import UserEditorPage from '../pages/users/Editor.page';
import DriverHome from '../pages/drivers/DriverHome';
import ReviewRequested from '../pages/drivers/ReviewRequested';
import Documents from '../pages/drivers/Documents';


const PrivateRoutes: React.FC = () => (
  <IonReactRouter>
    <IonMenu type='push' id="sidebar" contentId="menu">
      <Menu/>
    </IonMenu>
    <input className='dummy' name="email" type="text"/>
    <input className='dummy' type="password"/>
    <IonPage id='menu'>
      <IonRouterOutlet>
        <Route exact path={appSubDir+"home"}>
          <Home />
        </Route>
        <Route exact path={appSubDir+"reservations"}>
          <Reservations />
        </Route>
        <Route exact path={appSubDir+"account"}>
          <Account />
        </Route>
        <Route exact path={appSubDir+"users"}>
          <UsersPage />
        </Route>
        <Route exact path={appSubDir+"drivers"}>
          <DriverHome />
        </Route>
        <Route exact path={appSubDir+"drivers/review-requested"}>
          <ReviewRequested />
        </Route>
        <Route exact path={appSubDir+"drivers/documents"}>
          <Documents />
        </Route>
        <Route exact path={appSubDir+"users/:action"}>
          <UserEditorPage />
        </Route>
        <Route>
          <Redirect to={appSubDir+"home"} />
        </Route>
      </IonRouterOutlet>
    </IonPage>
  </IonReactRouter>
);

export default PrivateRoutes;
