import { IonText } from '@ionic/react';
import Countdown, { zeroPad } from 'react-countdown';

const CustomCountDown: React.FC = () => {
    const currentDate = new Date()
    // Expires At 12:00 AM
    const expiresAt = (currentDate.getTime() / 1000) + (24 * 3600) - currentDate.getHours() * 3600 - currentDate.getMinutes() * 60 - currentDate.getSeconds()
    return <Countdown date={expiresAt * 1000} renderer={(content: any) => {
        const { hours, minutes, seconds } = content
        return <h2 className='text-center'>
            <IonText color="primary">{zeroPad(hours)}</IonText>:
            <IonText color="primary">{zeroPad(minutes)}</IonText>:
            <IonText color="primary">{zeroPad(seconds)}</IonText>
        </h2>;
    }} />
};

export default CustomCountDown;
