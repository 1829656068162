import { IonButton, IonCol, IonRow,  IonText } from '@ionic/react';
import SimpleProduct from '../../types/product.type';
import ProductCard from './ProductCard';
import { splitInHalf } from '../../functions/string.functions';
type Props = {
    title: string
    products?: SimpleProduct[]
}
const ProductsGrid: React.FC<Props> = ({ title, products }) => {
    if(!products){
        return null
    }
    const titleParts = splitInHalf(title)
    return (
        <section className='h-products mb-50'>
            <IonRow>
                <IonCol>
                    <div className='title'>
                        <IonText color="primary" className='f'>{titleParts[0]}</IonText>
                        <IonText color="dark" className='s'> {titleParts[1]}</IonText>
                        <hr />
                    </div>
                </IonCol>
                <IonCol className='text-end'>
                    <IonButton fill='clear'>View All</IonButton>
                </IonCol>
            </IonRow>
            {products && <IonRow>
                {products.map((item, index) => <IonCol sizeMd='2' sizeLg='2'  sizeXl='2' sizeXs='1' key={index} size='1'>
                    <ProductCard product={item}/>
                </IonCol>)}
            </IonRow>}
        </section>
    );
};

export default ProductsGrid;
