import { IonButton, IonCol, IonRow, IonText } from '@ionic/react';
import SimpleProduct from '../../types/product.type';
import './ProductsVerticalBox.scss'
import ProductListItem from './ProductListItem';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { splitInHalf } from '../../functions/string.functions';
type Props = {
    title: string
    products: SimpleProduct[]
    slideOpts: any
}
const ProductsVerticalBox: React.FC<Props> = ({ title, products, slideOpts }) => {
    const titleParts = splitInHalf(title)
    return (
        <section className='v-products-box mb-50'>
            <div className='title'>{title}</div>
            {products && <Swiper {...slideOpts} modules={[Autoplay]}>
                {products.map((item, index) => <SwiperSlide key={index}>
                    <ProductListItem product={item} />
                </SwiperSlide>)}
            </Swiper>}
            <div className='btn-container'>
                <IonButton fill='outline' color="primary">View All</IonButton>
            </div>
        </section>
    );
};

export default ProductsVerticalBox;
