import { IonButton, IonCard, IonCardContent, IonIcon, IonText } from '@ionic/react';
import { baseUrl } from '../../configs/app';
import SimpleProduct from '../../types/product.type';
import './ProductCard.scss'
import { eye } from 'ionicons/icons';
type Props = {
    product: SimpleProduct
}
const ProductCard: React.FC<Props> = ({ product }) => {
    return (
        <IonCard className='product'>
            <IonCardContent>
                <div className='thumb-containter'>
                    <img src={baseUrl + product.image} alt="" />
                    <IonButton fill='clear'>
                        <IonIcon icon={eye} />
                    </IonButton>
                </div>

                <h2>{product.name}</h2>
                <div className='pricing'>
                    <IonText className='regular'>{product.regular_price} </IonText>
                    <IonText className='sale' color='primary'>{product.sale_price}/=</IonText>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default ProductCard;
