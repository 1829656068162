import { baseUrl } from '../../configs/app';
import HomeSection from '../../types/home.type';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const slideOpts = {
    autoplay: {
        delay: 4000
    },
    // loop: true,
    pagination: false,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 3,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 6,
            spaceBetween: 10
        },
        // when window width is >= 640px
        1440: {
            slidesPerView: 8,
            spaceBetween: 10
        }
    }
};
type Props = {
    data?:HomeSection
}
const CategorySwiper: React.FC<Props> = ({data}) => {
    if(!data){
        return null
    }
    return (
        <div className='mb-50'>
            {data.items && <Swiper {...slideOpts} modules={[Autoplay]}>
                {data.items.map((item, index) => <SwiperSlide key={index}>
                    <img src={baseUrl+item.file_path} width={item.width} height={item.height} alt='' style={{height:'auto'}}/>
                </SwiperSlide>)}
            </Swiper>}
        </div>
    );
};

export default CategorySwiper;
