import { IonButton, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar} from '@ionic/react';
import { add} from 'ionicons/icons';
import { useRef } from 'react';
import NewTripForm from './NewTripForm';
type Props = {
    onChange?: (item: string) => void;
}
const NewTripModal: React.FC<Props> = ({onChange}) => {
  const modal = useRef<HTMLIonModalElement>(null)
  
  return (
    <>
        <IonButton expand='block' onClick={() => modal.current?.present()}><IonIcon icon={add}/>Add</IonButton>
        <IonModal ref={modal} initialBreakpoint={0.95} breakpoints={[0, 0.25, 0.5, 0.90]}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Create New Trip</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" class='ion-padding' style={{overflow:"auto"}}>
                <NewTripForm/>
            </IonContent>
        </IonModal>    
    </>
  );
};

export default NewTripModal;
