import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import { useAppContext } from '../services/app-context.service';
import TitleBar from '../components/TitleBar';
import './Reservations.scss';
import { add } from 'ionicons/icons';
import NewTripModal from '../components/reservations/NewTripModal';
import { Loader } from '@googlemaps/js-api-loader';
import { googleApiKey } from '../configs/config.private';

const Reservations: React.FC = () => {
  const data = useAppContext() as any

  return (
    <>
      <TitleBar title='Reservations'/>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeLg='8' offsetLg='2' sizeXl='6' offsetXl='3'>
              <IonCard className='trips-filter'>
                <IonCardContent style={{padding:0}}>
                  <IonRow>
                    <IonCol size='9'>Search trip By customer name,email, phone or Trip ID</IonCol>
                    <IonCol size='3'><NewTripModal/></IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow className='trips'>
            <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3'>
              <IonItem>
                <div className='head' slot='start'>
                  <h4>JXOS</h4>
                  <div><IonText>01/02/23</IonText></div>
                  <IonText className='price' color="success">$ 102.65</IonText>
                </div>
                <div>
                  <h5>Noel Arzola</h5>
                  <div><IonText>customer@gmail.com</IonText></div>
                  <IonText>01248758745</IonText>
                </div>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3'>
              <IonItem>
                <div className='head' slot='start'>
                  <h4>JXOS</h4>
                  <div><IonText>01/02/23</IonText></div>
                  <IonText className='price' color="success">$ 102.65</IonText>
                </div>
                <div>
                  <h5>Noel Arzola</h5>
                  <div><IonText>customer@gmail.com</IonText></div>
                  <IonText>01248758745</IonText>
                </div>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3'>
              <IonItem>
                <div className='head' slot='start'>
                  <h4>JXOS</h4>
                  <div><IonText>01/02/23</IonText></div>
                  <IonText className='price' color="success">$ 102.65</IonText>
                </div>
                <div>
                  <h5>Noel Arzola</h5>
                  <div><IonText>customer@gmail.com</IonText></div>
                  <IonText>01248758745</IonText>
                </div>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3'>
              <IonItem>
                <div className='head' slot='start'>
                  <h4>JXOS</h4>
                  <div><IonText>01/02/23</IonText></div>
                  <IonText className='price' color="success">$ 102.65</IonText>
                </div>
                <div>
                  <h5>Noel Arzola</h5>
                  <div><IonText>customer@gmail.com</IonText></div>
                  <IonText>01248758745</IonText>
                </div>
              </IonItem>
            </IonCol>
            <IonCol size='12' sizeMd='6' sizeLg='4' sizeXl='3'>
              <IonItem>
                <div className='head' slot='start'>
                  <h4>JXOS</h4>
                  <div><IonText>01/02/23</IonText></div>
                  <IonText className='price' color="success">$ 102.65</IonText>
                </div>
                <div>
                  <h5>Noel Arzola</h5>
                  <div><IonText>customer@gmail.com</IonText></div>
                  <IonText>01248758745</IonText>
                </div>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Reservations;
