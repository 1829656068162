import { IonButton, IonButtons, IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { bag, logoFacebook, menu, people, person, phoneLandscape, search } from 'ionicons/icons';
import './PrimaryMenu.scss'
import { appSubDir } from '../configs/app';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const PrimaryMenu: React.FC = () => {
    return (
        <nav id="primary-menu">
            {window.screen.width > 536 && <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding'>
                    <IonRow>
                        <IonCol>
                            {window.screen.width > 800 ? <ul className='menu'>
                                <li>
                                    <a href="#">About</a>
                                </li>
                                <li>
                                    <a href="#">Shop</a>
                                </li>
                                <li>
                                    <a href="#">Menu Item 1</a>
                                    <ul className='level level-1'>
                                        <li><a href="#">Menu Item 1.1</a></li>
                                        <li><a href="#">Menu Item 1.2</a></li>
                                        <li><a href="#">Menu Item 1.3</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Menu Item 2</a>
                                    <ul className='level level-1'>
                                        <li><a href="#">Menu Item 2.1</a></li>
                                        <li><a href="#">Menu Item 2.2</a></li>
                                        <li><a href="#">Menu Item 2.3</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Menu Item 3</a>
                                    <ul className='level level-1'>
                                        <li><a href="#">Menu Item 3.1</a></li>
                                        <li><a href="#">Menu Item 3.2</a></li>
                                        <li><a href="#">Menu Item 3.3</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Contact</a>
                                </li>
                            </ul> : <IonButtons>
                                <IonButton fill='clear'>
                                    <IonIcon icon={menu} />
                                </IonButton>
                            </IonButtons>}
                        </IonCol>
                        <IonCol className='text-end'>
                            <IonButtons>
                                <IonButton fill='clear'>
                                    <IonText color="light" slot='start' style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPhone} /></IonText>
                                    <IonText color="light"><b>Hotline : +8801689636569</b></IonText>
                                </IonButton>
                                <IonButton fill='clear'>
                                    <IonIcon icon={people} color="light" slot='start' />
                                    <IonText color="light"><b>FB Group</b></IonText>
                                </IonButton>
                                <IonButton fill='clear'>
                                    <IonIcon icon={logoFacebook} color="light" slot='start' />
                                    <IonText color="light"><b>FB Page</b></IonText>
                                </IonButton>
                            </IonButtons>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>}
        </nav>
    );
};

export default PrimaryMenu;
