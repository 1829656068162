import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPopover, IonRow, IonSpinner, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { add, close, person, search } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import User from '../../types/user.type';
import { useAppContext } from '../../services/app-context.service';
import { apiBaseUrl } from '../../configs/app';
type Props = {
    setCustomer?: (item: User) => void;
}
const BookingContactSearch: React.FC<Props> = ({ setCustomer }) => {
    const store = useAppContext() as any
    const modal = useRef<HTMLIonModalElement>(null)
    const searchText = useRef<HTMLIonInputElement>(null)
    const pages = useRef({ last_page: 1, current_page: 1 })
    const [customers, setCustomers] = useState([] as User[])
    const [page, SetPage] = useState(1)
    const [loading, SetLoading] = useState(false)

    const fetchData = () => {
        if (loading) {
            return null
        }
        SetLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + store.user.token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        } as any;
        const paramsObj = { page: page.toString() } as any
        if (searchText.current?.value) {
            paramsObj.s = searchText.current.value
        }
        const params = new URLSearchParams(paramsObj);

        fetch(apiBaseUrl + "admin/search/customer?" + params.toString(), requestOptions).then((res) => (res.json()))
            .then((res) => {
                pages.current.last_page = res.last_page
                pages.current.current_page = res.current_page
                setCustomers(res.data)
            })
            .catch((err) => console.log(err))
            .finally(() => SetLoading(false))
    }
    return (
        <>
            <IonButton slot='end' color="dark" fill='clear' size='small' onClick={() => modal.current?.present()}><IonIcon icon={search} /></IonButton>
            <IonModal ref={modal} initialBreakpoint={0.77} breakpoints={[0, 0.25, 0.5,0.77, 0.90]} keepContentsMounted={true}>
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle>Search Exsisting Booking Contact</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem color="light">
                        <IonIcon icon={search} slot='start' />
                        <IonLabel position='floating'>Start Typing To Search Customer</IonLabel>
                        <IonInput clearInput={true} ref={searchText} debounce={300} onIonChange={fetchData} />
                    </IonItem>
                    {loading && <div className='container'><IonSpinner color="primary" name="bubbles" /></div>}
                    {!loading && customers && customers.map((customer, index) => <IonItem key={index} button={true} onClick={() => {
                        if (setCustomer) {
                            setCustomer(customer)
                            modal.current?.dismiss()
                        }
                    }}>
                        <IonIcon slot='start' size='large' icon={person} />
                        <div className='contact'>
                            <h5>{customer.f_name} {customer.l_name}</h5>
                            <div><IonText>{customer.email}</IonText></div>
                            <IonText>{customer.phone}</IonText>
                        </div>
                    </IonItem>)}
                </IonContent>
            </IonModal>
        </>
    );
};

export default BookingContactSearch;
