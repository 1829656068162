import { IonCol, IonContent, IonGrid, IonRow, useIonViewDidEnter } from '@ionic/react';
import { useAppContext } from '../services/app-context.service';
import TitleBar from '../components/TitleBar';

const Account: React.FC = () => {
  const data = useAppContext() as any
  useIonViewDidEnter(()=>{
  })

  return (
    <>
      <TitleBar title='Account'/>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size='12' size-md="6">
              <img src="https://static.vecteezy.com/system/resources/previews/003/692/287/original/big-sale-discount-promotion-banner-template-with-blank-product-podium-scene-graphic-free-vector.jpg"/>
            </IonCol>
            <IonCol size='6'>
              <img src="https://static.vecteezy.com/system/resources/previews/003/692/287/original/big-sale-discount-promotion-banner-template-with-blank-product-podium-scene-graphic-free-vector.jpg"/>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default Account;
