import React, { createContext, useContext,useEffect,useState } from 'react';

export const defaultData = {
	innerWidth:window.innerWidth,
	user:JSON.parse(window.localStorage.getItem('user')) || {
		id:"",
		name:"",
		email:"",
		phone:"",
		institute:"",
		bmdc_reg_no:"",
		email_verified_at:"",
		expires_at:"",
		created_at:"",
		updated_at:"",
		token:""
	}
};

const AppContext = createContext(null);
const AppContextUpdater = createContext(null);

export const AppContextProvider = ({ children }) => {
	const [data,SetData] = useState(defaultData);
    const SetAppData = (newData)=>{
        SetData(newData)
    }
	useEffect(()=>{
		const onScreenResize = (e)=>{
			SetData((currentData)=>{
				const innerWidth = window.innerWidth
				return {...currentData,...{innerWidth}}
			})
		}
		window.addEventListener("resize", onScreenResize);
		return ()=>{
			window.removeEventListener("resize", onScreenResize);
		}
	},[])
	return <AppContext.Provider value={data}>
        <AppContextUpdater.Provider value={SetAppData}>
            {children}
        </AppContextUpdater.Provider>
    </AppContext.Provider>
};

export const useAppContext = () => {
	const store = useContext(AppContext);
	if (!store) {
		throw new Error('useAppContext must be used within a AppContextProvider.');
	}
	return store;
};
export const useAppContextUpdater = () => {
	return useContext(AppContextUpdater);
};
