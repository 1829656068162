import TopBar from './TopBar';
import PrimaryMenu from './PrimaryMenu';
import { Link } from 'react-router-dom';
import { appSubDir } from '../configs/app';
import { IonButton, IonCol, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import './Header.scss'
import SearchBar from './SearchBar';
import { cartOutline, person, personOutline } from 'ionicons/icons';

const Header: React.FC = () => {

    return <>
        {/* <TopBar /> */}
        <header>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <IonRow className='desktop'>
                        <IonCol size='12' sizeMd='4'>
                            <Link to={appSubDir} className='brand'>
                                <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                                <h1>Bbazar</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='12' sizeMd='5'>
                            <SearchBar />
                        </IonCol>
                        <IonCol size='12' sizeMd='3'>
                            <div className='buttons'>
                                <IonButton fill='clear'><IonIcon size='large' icon={cartOutline} /></IonButton>
                                <IonButton fill='clear'><IonIcon size='large' icon={personOutline} /></IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className='tab'>
                        <IonCol size='6'>
                            <Link to={appSubDir} className='brand'>
                                <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                                <h1>Bbazar</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='6'>
                            <div className='buttons'>
                                <IonButton fill='clear'><IonIcon size='large' icon={cartOutline} /></IonButton>
                                <IonButton fill='clear'><IonIcon size='large' icon={personOutline} /></IonButton>
                            </div>
                        </IonCol>
                        <IonCol size='12'>
                            <SearchBar />
                        </IonCol>
                    </IonRow>
                    <IonRow className='mobile'>
                        <Link to={appSubDir} className='brand'>
                            <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                            <h1>Bbazar</h1>
                        </Link>
                    </IonRow>
                </IonCol>
            </IonRow>
        </header>
        <PrimaryMenu />
    </>
};

export default Header;
