import { useEffect, useState } from 'react';
import { apiBaseUrl } from '../../configs/app';
import SimpleProduct from '../../types/product.type';
import ProductsVerticalBox from '../Products/ProductsVerticalBox';

const slideOpts = {
    autoplay: {
        delay: 5000
    },
    pagination: false,
    direction: "vertical",
    // loop: true,
    slidesPerView: 4,
    spaceBetween: 10
};
const HalfDiscount: React.FC = () => {
    const localData = window.localStorage.getItem("home-50-percent-discount")
    const [items, setItems] = useState((localData ? JSON.parse(localData) : []) as SimpleProduct[])
    function fetchData() {
        fetch(apiBaseUrl + "products/random?items_per_page=12&price_type=Discount&price_from=40&price_to=50&random_order=true")
            .then(response => response.json())
            .then(result => {
                setItems(result.products.data)
                window.localStorage.setItem("home-50-percent-discount", JSON.stringify(result.products.data))

            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <ProductsVerticalBox title='50% Off' products={items} slideOpts={slideOpts}/>
    );
};

export default HalfDiscount;
