import { IonButton, IonCol, IonRow,  IonText } from '@ionic/react';
import SimpleProduct from '../../types/product.type';
import ProductCard from './ProductCard';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { splitInHalf } from '../../functions/string.functions';
import './ProductsHorizontal.scss'
type Props = {
    title: string
    products?: SimpleProduct[]
    delay: number
}
const ProductsHorizontal: React.FC<Props> = ({ title, products, delay }) => {
    if(!products){
        return null
    }
    const titleParts = splitInHalf(title)
    const slideOpts = {
        autoplay: {
            delay: delay
        },
        loop: true,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 3,
                spaceBetween: 0
            },
            // when window width is >= 480px
            768: {
                slidesPerView: 6,
                spaceBetween: 10
            },
            // when window width is >= 640px
            1440: {
                slidesPerView: 8,
                spaceBetween: 10
            },
            1600: {
                slidesPerView: 12,
                spaceBetween: 10
            }
        }
    };
    return (
        <section className='h-products mb-50'>
            <IonRow>
                <IonCol>
                    <div className='title'>
                        <IonText color="primary" className='f'>{titleParts[0]}</IonText>
                        <IonText color="dark" className='s'> {titleParts[1]}</IonText>
                        <hr />
                    </div>
                </IonCol>
                <IonCol className='text-end'>
                    <IonButton fill='clear'>View All</IonButton>
                </IonCol>
            </IonRow>
            {products && <Swiper {...slideOpts} modules={[Autoplay]}>
                {products.map((item, index) => <SwiperSlide key={index}>
                    <ProductCard product={item}/>
                </SwiperSlide>)}
            </Swiper>}
        </section>
    );
};

export default ProductsHorizontal;
