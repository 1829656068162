import { IonCol, IonContent, IonRow } from '@ionic/react';
import FlashSale from '../components/Home/FlashSale';
import BeautyProducts from '../components/Home/BeautyProducts';
import HalfDiscount from '../components/Home/HalfDiscount';
import StockDeals from '../components/Home/StockDeals';
import { useEffect, useState } from 'react';
import HomeSection from '../types/home.type';
import { apiBaseUrl, appSubDir, baseUrl } from '../configs/app';
import CategorySwiper from '../components/Home/CategorySwiper';
import SlidesMini from '../components/Home/SlidesMini';
import ProductsHorizontal from '../components/Products/ProductsHorizontal';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import ProductsGrid from '../components/Products/ProductsGrid';
import AllCategories from '../components/Home/AllCategories';
import './Home.scss';
import CategorySwiperVerticle from '../components/Home/CategorySwiperVerticle';

const Home: React.FC = () => {
    const localData = window.localStorage.getItem("home-screen-data")
    const [items, setItems] = useState((localData ? JSON.parse(localData) : []) as HomeSection[])
    function fetchData() {
        fetch(apiBaseUrl + "app-data/home-screen?items_per_page=24&img=web")
            .then(response => response.json())
            .then(result => {
                setItems(result.data)
                // console.log(result.data)
                window.localStorage.setItem("home-screen-data", JSON.stringify(result.data))

            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        fetchData()
    }, [])
    let bannerCount = 0
    function getBannerRow(index: number) {
        bannerCount++
        const localItems = items.filter(item => item.type === "Banner" && item.items && item.items[0].file_path).slice((index * 3), index * 3 + 3)
        if (localItems.length === 3) {
            return <IonRow style={{ marginBottom: 50 }}>
                {items.filter(item => item.type === "Banner" && item.items && item.items[0].file_path).slice((index * 3), index * 3 + 3).map((item2, index2) => <IonCol key={index2} size='12' sizeMd='4'>
                    {item2.items && <Link to={appSubDir}><img src={baseUrl + item2.items[0].file_path} /></Link>}
                </IonCol>)}
            </IonRow>
        }
    }
    return (
        <>
            <IonContent fullscreen>
                <Header />
                <IonRow>
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                        <IonRow className='home-slider mb-50'>
                            <IonCol sizeLg='3'>
                                <AllCategories />
                            </IonCol>
                            <IonCol sizeLg='6'>
                                <SlidesMini slides={items.find(item => item.type === 'Slider')?.items} />
                            </IonCol>
                            <IonCol sizeLg='3'>
                                <CategorySwiperVerticle data={items.find(item => item.type === 'Swiper')} />
                            </IonCol>
                        </IonRow>
                        {window.screen.width < 700 && <CategorySwiper data={items.find(item => item.type === 'Swiper')} />}
                        {getBannerRow(3)}
                        <FlashSale products={items.find(item => item.type === 'Flash Sale')?.products?.data} />
                        {getBannerRow(1)}
                        <ProductsHorizontal title='New Arrival' products={items.find(item => item.type === 'New Arrival')?.products?.data} delay={4500} />
                        {getBannerRow(0)}
                        <IonRow>
                            <IonCol sizeLg='4'>
                                <BeautyProducts />
                            </IonCol>
                            <IonCol sizeLg='4'>
                                <HalfDiscount />
                            </IonCol>
                            <IonCol sizeLg='4'>
                                <StockDeals />
                            </IonCol>
                        </IonRow>
                        <ProductsGrid title='All Products' products={items.find(item => item.type === 'All Products')?.products?.data} />
                        {getBannerRow(4)}
                    </IonCol>
                </IonRow>
            </IonContent>
        </>
    );
};

export default Home;
