import { IonContent } from '@ionic/react';
import PrimaryMenu from '../components/PrimaryMenu';
// import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';

const Contact: React.FC = () => {
    return (
        <>
            <IonContent fullscreen>
                <PrimaryMenu />
                This is Contact
            </IonContent>
        </>
    );
};

export default Contact;
