import { IonBadge, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow, IonSelect, IonSelectOption, IonSpinner, useIonViewDidEnter } from '@ionic/react';
import { add, pencil, search } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import TitleBar from '../../components/TitleBar';
import DeleteConfirmation from '../../components/users/Delete.component';
import EditorPanel from '../../components/users/EditorPanel.component';
import { apiBaseUrl, appSubDir } from '../../configs/app';
import { useAppContext } from '../../services/app-context.service';
import Role from '../../types/user-role.type';
import User from '../../types/user.type';

const UsersPage = () =>{
  const urlParams = new URLSearchParams(window.location.search);
  const store = useAppContext() as any
  const history = useHistory() as any
  const [items,SetItems] = useState([])
  const [page,SetPage] = useState(1)
  const [total,SetTotal] = useState(null)
  const [editUser,SetEditUser] = useState({} as User)
  const pages = useRef({last_page:1,current_page:1})
  const searchText = useRef({} as any)
  const searchRole = useRef({} as any)
  const [loading,SetLoading] = useState(false)
  const [roles,SetRoles] = useState([] as Role[])
  const fetchData = ()=>{
    if(loading){
      console.log("was loading")
      return null
    }
    SetLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer "+store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;
    const paramsObj = {page:page.toString()} as any
    if(searchText.current.value){
      paramsObj.s = searchText.current.value
    }
    if(searchRole.current.value){
      paramsObj.r = searchRole.current.value
    }
    const params = new URLSearchParams(paramsObj);

    fetch(apiBaseUrl+"admin/users?"+params.toString(),requestOptions).then((res)=>(res.json()))
    .then((res)=>{
      pages.current.last_page = res.users.last_page
      pages.current.current_page = res.users.current_page
      SetItems(res.users.data.map((user:User,index:number)=>{
        const roles = res.roles[index] as Role[]
        return {...user,...{roles}} as User
      }))
    })
    .catch((err)=>console.log(err))
    .finally(()=>SetLoading(false))
  }
  const fetchRoles = ()=>{
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer "+store.user.token);

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    } as any;

    fetch(apiBaseUrl+"admin/roles",requestOptions).then((res)=>(res.json()))
    .then((roles:Role[])=>{
      SetRoles(roles)
    })
    .catch((err)=>console.log(err))
  }
  useEffect(()=>{
    fetchRoles()
    fetchData()
  },[])
  function addNew(){
    if(store.innerWidth < 1240){
      history.push(appSubDir+"users/add",{roles})
    }else{
      SetEditUser({} as User)
    }
  }
  function editUserAction(user:User){
    if(store.innerWidth < 1240){
      history.push(appSubDir+"users/edit",{user,roles})
    }else{
      SetEditUser(user)
    }
  }
  return <>
      <TitleBar title='User Management'/>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating"><IonIcon icon={search}/>Search By name, email & Phone</IonLabel>
                    <IonInput debounce={500} ref={searchText} clearInput={true} onIonChange={fetchData} defaultValue=""/>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating"><IonIcon icon={search}/>Search By Role</IonLabel>
                    <IonSelect ref={searchRole} onIonChange={()=>{
                      if(searchRole.current.value == "clear"){
                        searchRole.current.value = ""
                      }
                      fetchData()
                    }}>
                      {roles.map((item,index)=>(<IonSelectOption key={index} value={item.name}>{item.display_name}</IonSelectOption>)) }
                      <IonSelectOption value="clear">Clear Selection</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonButton onClick={addNew} color="light"><IonIcon icon={add}/>Add New</IonButton>
                </IonCol>
              </IonRow>
              {loading && <div className='text-center'><IonSpinner color="primary" name="bubbles"/></div>}
              {!loading && <div className='table-responsive'>
                <table className='table-scroll'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item:User,index)=>{
                      return <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.f_name} {item.l_name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.roles && item.roles.map((role,roleIndex)=>{
                          return <IonBadge key={roleIndex} color='light' style={{fontSize:16}}>{role.display_name}</IonBadge>
                        })}</td>
                        <td>
                          {store.user.id != item.id && <IonButton onClick={()=>editUserAction(item)} fill='clear' color="tertiary"><IonIcon icon={pencil}/></IonButton>}
                          {store.user.id != item.id && <DeleteConfirmation user={item} onSuccess={fetchData}/>}
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>}
            </IonCol>
            <EditorPanel roles={roles} user={editUser} onUpdate={fetchData}/>
          </IonRow>
        </IonGrid>
          {/* {!loading && <div className='row'>
            <div className='col-md-12 d-flex justify-content-center'>
              <ul className='pagination'>
                  {pages.current.last_page > 1 &&[...Array(pages.current.last_page).keys()].map((item,index)=>{
                    const current = ((item+1) == pages.current.current_page)
                    return <li key={index} onClick={(e)=>{
                      e.preventDefault()
                      !current && SetPage(item+1)
                    }}
                      className={current?'page-item active':'page-item'}>
                      <a href='#' className="page-link">{item+1}</a>
                    </li>
                  })}
              </ul>
            </div>
          </div>} */}
      </IonContent>
    </>
};
export default UsersPage
