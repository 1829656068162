import { baseUrl } from '../../configs/app';
import HomeSection, { HomeItem } from '../../types/home.type';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
type Slide = {
    url?: string
    image: string
}
type Props = {
    slides?: HomeItem[]
}
const SlidesMini: React.FC<Props> = ({ slides }) => {
    if(!slides){
        return null
    }
    return (
        <>
            {slides && <Swiper
                autoplay={{
                    pauseOnMouseEnter: true,
                    delay: 3000
                }}
                pagination={{
                    clickable: true,
                }}
                loop={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mini-slider">
                {slides.map((item, index) => <SwiperSlide key={index}>
                    <img src={baseUrl + item.file_path} alt="" />
                </SwiperSlide>)}
            </Swiper>}
        </>
    );
};

export default SlidesMini;
