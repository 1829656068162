import { IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useAppContext } from '../../services/app-context.service';
import User from '../../types/user.type';
import Role from '../../types/user-role.type';
import UserForm from '../../components/users/Form.component';
import { arrowBack } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router';

const UserEditorPage: React.FC = () => {
  const store = useAppContext() as any
  const location = useLocation() as any
  const history = useHistory() as any
  const user = location.state && location.state.user ? location.state.user as User : {} as User
  const roles = location.state && location.state.roles ? location.state.roles as Role[] : [] as Role[]
  function goBack(){
    history.goBack()
  }
  return <>
    <IonHeader>
      <IonToolbar>
        <IonButton onClick={goBack}  fill='clear' slot="start" color="dark">
          <IonIcon size='large' icon={arrowBack}></IonIcon>
        </IonButton>
        <IonTitle>{user.id ? `Edit User #${user.id}`:'Add New User'}</IonTitle>
      </IonToolbar>
    </IonHeader>    
    <IonContent fullscreen class="ion-padding">
      <UserForm user={user} roles={roles} onUpdate={goBack}/>
    </IonContent>
  </>
    
};
export default UserEditorPage