import { baseUrl } from '../../configs/app';
import HomeSection from '../../types/home.type';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const slideOpts = {
    autoplay: {
        pauseOnMouseEnter: true,
        delay: 6000
    },
    // loop: true,
    direction: "vertical",
    pagination: false,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 2,
            spaceBetween: 0
        },
        1024: {
            slidesPerView: 2,
            spaceBetween: 0
        },
        1440: {
            slidesPerView: 2,
            spaceBetween: 0
        }
    }
} as any;
type Props = {
    data?: HomeSection
}
const CategorySwiperVerticle: React.FC<Props> = ({ data }) => {
    if (!data || !data.items) {
        return null
    }
    const midPoint = Math.ceil(data.items.length / 2)
    const set1 = data.items.slice(0,midPoint)
    const set2 = data.items.slice(midPoint+1)
    return (
        <div style={{ display: "flex" }}>
            {set1 && <Swiper {...slideOpts} autoplay={{
                delay: 5000
            }}
                modules={[Autoplay]}>
                {set1.map((item, index) => <SwiperSlide key={index}>
                    <img src={baseUrl + item.file_path} width={item.width} height={item.height} alt='' style={{ height: 'auto' }} />
                </SwiperSlide>)}
            </Swiper>}
            {set2 && <Swiper {...slideOpts} modules={[Autoplay]}>
                {set2.map((item, index) => <SwiperSlide key={index}>
                    <img src={baseUrl + item.file_path} width={item.width} height={item.height} alt='' style={{ height: 'auto' }} />
                </SwiperSlide>)}
            </Swiper>}
        </div>
    );
};

export default CategorySwiperVerticle;
