
export const types = [
    "OL"            ,// "Operator's License",
    "DVLA_DL_ID"    ,// "DVLA driver's licence ID Card",
    "TFL_DL_ID"     ,// "TFL Drivers Licence ID Card",
    "IC"            ,// "Insurance Certificate",
    "NIC"           ,// "National Insurance Card",
    "TFL_VC"        ,// "TFL vehicle certificate",
    "DVLA_V5C"      ,// "DVLA V5C",
    "VAT_C"         // "VAT certificate"
  ]
  export const titles = [
    "Operator's License",
    "DVLA driver's license ID Card",
    "TFL Drivers license ID Card",
    "Insurance Certificate",
    "National Insurance Card",
    "TFL vehicle certificate",
    "DVLA V5C",
    "VAT certificate"
  ]
  export const frontTitles = [
    "Operator's License Part - A",
    "license ID Card Front",
    "license ID Card Front",
    "Insurance Certificate",
    "Insurance Card Front",
    "TFL vehicle certificate",
    titles[6]+" Front",
    titles[7]+" Front"
  ]
  export const backTitles = [
    "Operator's License Part - B",
    "license ID Card Back",
    titles[2]+" Back",
    titles[3]+" Back",
    titles[4]+" Back",
    titles[5]+" Back",
    titles[6]+" Back",
    titles[7]+" Back",
    titles[8]+" Back"
  ]
  export const docNumerTitles = [
    titles[0]+" NO",
    titles[1]+" Number",
    titles[2]+" Number",
    titles[3]+" Number",
    titles[4]+" Number",
    titles[5]+" Number",
    titles[6]+" Certificate Number",
    titles[7]+" Back"
  ]
  export const requireFront = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true
  ]
  export const requireBack = [
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true
  ]
  export const requireLicenseNo = [
    false,
    true,
    true,
    false,
    true,
    true,
    true,
    true
  ]
  export const requireExpireDate = [
    false,
    true,
    true,
    true,
    false,
    true,
    false,
    true
  ]