import { IonButton, IonButtons, IonHeader, IonIcon, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, person, power } from 'ionicons/icons';
import { appSubDir } from '../configs/app';
import { defaultData,useAppContext, useAppContextUpdater } from '../services/app-context.service';
import { useHistory } from 'react-router-dom';
type Props = {
  title:string
  backBtn?:boolean
}

const TitleBar: React.FC<Props> = (props) => {
  const data = useAppContext() as any
  const setData = useAppContextUpdater() as any
  const history = useHistory()
  const doLogOut = ()=>{
    window.localStorage.removeItem('user')
    setData(defaultData)
    window.location.replace(appSubDir)
  }
  function goBack(){
    history.goBack()
  }
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          {props.backBtn ? <IonButton onClick={goBack}>
            <IonIcon icon={arrowBack}></IonIcon>
          </IonButton>:
          <IonMenuButton></IonMenuButton>}
        </IonButtons>
        <IonTitle>{props.title}</IonTitle>
        <IonButton routerLink='/account' fill='clear' slot="end" color="tertiary">
          <IonIcon size='large' icon={person}></IonIcon>
        </IonButton>
        <IonButton onClick={doLogOut} fill='clear' slot="end" color="tertiary">
          <IonIcon size='large' icon={power}></IonIcon>
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default TitleBar;
