import { useAppContext } from '../services/app-context.service';
type Props = {
  children: JSX.Element,
  publicRoutes: JSX.Element,
};
const AuthGuard: React.FC<Props> = ({publicRoutes,children}) => {
  const data = useAppContext() as any
  if(data.user.token){
    return <>{children}</>
  }else{
    return <>{publicRoutes}</>
  }
};

export default AuthGuard;
