import { IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import './AutoCompleteModal.scss';
import { useRef, useState } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import BookingPoint from '../../types/booking-point.type';
import airlines from '../../data/airlines.data';
import AirelineSelectorModal from './AirelineSelectorModal';
import Airline from '../../types/airline.type';
import { googleApiKey } from '../../configs/config.private';
// import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
type Props = {
  flightInfoEnabled:boolean
  placeholder:string
  title:string
  onUpdate:any
  currentPoint:BookingPoint
}
const AutoCompleteModal: React.FC<Props> = ({currentPoint,flightInfoEnabled,placeholder,title,onUpdate}) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const airlineModal = useRef<HTMLIonModalElement>(null)
  const airlineInputRef = useRef<HTMLIonSelectElement>(null)
  const flightInputRef = useRef<HTMLIonInputElement>(null)
  const [flightFieldVisible, SetFlightFieldVisible] = useState(false)
  const [address, SetAddress] = useState({} as BookingPoint)
  const [selectedAirline, setSelectedAirline] = useState({} as Airline);
  useIonViewDidEnter(()=>{
    // Show us the notification payload if the app is open on our device
    // PushNotifications.addListener('pushNotificationReceived',
    //   (notification: PushNotificationSchema) => {
    //     console.log('Push received: Home' + JSON.stringify(notification));
    //   }
    // );
    // PushNotifications.addListener('pushNotificationActionPerformed', (notification:any) => {
    //   console.log('Push notification action performed', notification.data.title, notification.data.body);
    // });
    const tabBar = document.querySelector('ion-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  })

  function onChange(e:any){
    SetAddress({
      address: e.value.description,
      placeId: e.value.place_id,
      isAirport: e.value.types.includes('airport')
    } as BookingPoint)
    console.log(e)
  }
  function onFlightChange(e:any){
    const flightAirline = airlineInputRef.current?.value
    const flightNo = flightInputRef.current?.value
    SetAddress({
      ...address,flightAirline,flightNo
    } as BookingPoint)
    // console.log(e)
  }
  function confirm() {
    modal.current?.dismiss(address, 'confirm');
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === 'confirm') {
      console.log(`Hello, ${ev.detail.data}!`);
      onUpdate(ev.detail.data)
    }
  }
  const onAirlineChange = (selected: any) => {
    setSelectedAirline(selected);
    console.log(selected)
    airlineModal.current?.dismiss();
  }
  return (
    <>
      <IonText
        className='address-field'
        color={currentPoint.address ? "dark" : "medium"}
        onClick={() => modal.current?.present()}>{currentPoint.address ? currentPoint.address : placeholder}</IonText>
      <IonModal ref={modal} initialBreakpoint={0.90} breakpoints={[0, 0.25, 0.5, 0.90]} onWillDismiss={(ev) => onWillDismiss(ev)}>
        <IonHeader>
          <IonToolbar color='medium'>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>Cancel</IonButton>
            </IonButtons>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={() => confirm()}>
                Confirm
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light" class='ion-padding'>
            <div className='autocomplete'>
              <GooglePlacesAutocomplete autocompletionRequest={{
                  componentRestrictions: { country: "uk" }
                }}
                apiKey={googleApiKey} selectProps={{placeholder,onChange}}/>
            </div>
            {flightInfoEnabled && address.isAirport && <IonItem>
              <IonText
                style={{width:selectedAirline.iata ?"40%":"100%"}}
                color="dark"
                onClick={() => airlineModal.current?.present()}>Airline</IonText>
              {selectedAirline.iata && <IonText
                style={{width:"60%"}}
                color="medium"
                onClick={() => airlineModal.current?.present()}>{selectedAirline.iata} - {selectedAirline.name}</IonText>}
            </IonItem>}
            {flightInfoEnabled && address.isAirport && <IonItem className={`${false && 'ion-invalid'}`}>
              <IonLabel position="floating">Flight Number</IonLabel>
              <IonInput ref={flightInputRef} clearInput={true}/>
              {false && <IonNote slot='error'>Error</IonNote>}
            </IonItem>}
        </IonContent>
      </IonModal>
      <IonModal ref={airlineModal}>
        <AirelineSelectorModal
          title="Select Airline"
          items={airlines}
          selectedItem={selectedAirline}
          onSelectionCancel={() => airlineModal.current?.dismiss()}
          onSelectionChange={onAirlineChange}
        />
      </IonModal>

    </>
  );
};

export default AutoCompleteModal;
