import { IonButton, IonDatetime, IonDatetimeButton, IonIcon, IonItem, IonLabel, IonModal, IonNote, IonSelect, IonSelectOption, IonSpinner, IonTextarea, useIonViewDidEnter } from '@ionic/react';
import { location, addCircle, calendar, carSport, pencil, play, stopCircle, trash } from 'ionicons/icons';
import { useRef, useState } from 'react';
import BookingPoint from '../../types/booking-point.type';
import AutoCompleteModal from './AutoCompleteModal';
import { useAppContext } from '../../services/app-context.service';
import { perMiles, perMin } from '../../configs/app';
import './NewTripForm.scss';
import mapApiLoader from '../../services/map-loader.services';
import BookingContactPicker from './BookingContactPicker';
import User from '../../types/user.type';
type Props = {
  onTripCreate?: (item: string) => void;
}
type Priceing = {
  loading: boolean
  timeInSec: number
  distanceInMeter: number
  cost: number
  formatedTime: string[]
  formatedDistance: string[]
  carCount: number
}
function formateTime(priceing: Priceing) {
  const hours = Math.floor(priceing.timeInSec / 3600);
  const min = Math.floor((priceing.timeInSec - hours * 3600) / 60);
  let output = []
  if (hours > 0) {
    output.push(hours.toString().padStart(2, "0") + ' Hrs')
  }
  if (min > 0) {
    output.push(min.toString().padStart(2, "0") + ' Mins')
  }
  if (output.length > 1) {
    return output.slice(0, 2)
  }
  return output[0].split(" ")
}

function formateDistance(priceing: Priceing) {
  const miles = Math.round(priceing.distanceInMeter / 1609.34);
  let output = []
  if (miles > 0) {
    output.push(miles.toString())
    output.push('Miles')
  }
  return output
}
function calculateCosting(priceing: Priceing) {
  return (priceing.distanceInMeter / 1609.34 * perMiles) + ((priceing.timeInSec / 60) * perMin)
}

const NewTripForm: React.FC<Props> = ({ onTripCreate }) => {
  const data = useAppContext() as any
  const [stopPoints, SetStopPoints] = useState([{}, {}] as BookingPoint[])
  const dateTimeRef = useRef<HTMLIonDatetimeElement>(null)
  const [checkoutEnabled, setCheckoutEnabled] = useState(false)
  const [priceing, setPriceing] = useState({ carCount: 1 } as Priceing)
  const [customer, setCustomer] = useState<User|undefined>()
  useIonViewDidEnter(() => {
    const tabBar = document.querySelector('ion-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  })
  function addStopPointField() {
    stopPoints.splice(stopPoints.length - 1, 0, {} as any)
    SetStopPoints([...stopPoints])
  }
  function removeStopPointField(index: number) {
    stopPoints.splice(index, 1)
    SetStopPoints([...stopPoints])
  }
  function isValid() {
    return !!dateTimeRef.current && !!dateTimeRef.current.value && isAllAddressReady() && !fromToSame()
  }
  function isAllAddressReady() {
    return stopPoints.filter((points) => !points.placeId).length === 0;
  }

  function fromToSame() {
    return stopPoints.length === 2 && stopPoints[0].placeId === stopPoints[1].placeId;
  }

  function onChange() {
    setCheckoutEnabled(isValid())
  }
  function onChechout() {
    console.log(stopPoints)
    console.log(priceing)
    // console.log(isValid())
  }
  function updateStopPointField(index: number, newDate: BookingPoint) {
    stopPoints[index] = newDate
    SetStopPoints([...stopPoints])
    onChange()
    if (isAllAddressReady()) {
      calculatePricing();
    }
  }

  function calculatePricing() {
    if (!isAllAddressReady()) {
      return null
    }
    priceing.loading = true;
    setPriceing({ ...priceing });
    // console.log(stopPoints)
    // const points = [{placeId:'ChIJ6W3FzTRydkgRZ0H2Q1VT548'},{placeId:'ChIJHWVqoPqPcUgROYxIBZwvPWo'},{placeId:'ChIJUS3lzJ-bcUgR0Q61KAY44No'}] as BookingPoint[]
    // const origins = points.slice(0,-1) as google.maps.Place[]
    // const destinations = points.slice(1) as google.maps.Place[]
    const origins = stopPoints.slice(0, -1).map((item) => ({ placeId: item.placeId } as google.maps.Place))
    const destinations = stopPoints.slice(1).map((item) => ({ placeId: item.placeId } as google.maps.Place))
    mapApiLoader.load().then(async () => {

      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins,
          destinations,
          travelMode: google.maps.TravelMode.DRIVING,
        } as google.maps.DistanceMatrixRequest, callback);

      function callback(response: google.maps.DistanceMatrixResponse | null, status: google.maps.DistanceMatrixStatus) {
        if (response && status === "OK") {
          priceing.distanceInMeter = priceing.timeInSec = 0
          origins.map((origin, index) => {
            priceing.distanceInMeter += response.rows[index].elements[index].distance.value
            priceing.timeInSec += response.rows[index].elements[index].duration.value
          })
          priceing.formatedTime = formateTime(priceing)
          priceing.formatedDistance = formateDistance(priceing)
          priceing.cost = calculateCosting(priceing)
        } else {
          console.log("Something went wrong")
        }
        priceing.loading = false;
        setPriceing({ ...priceing });
      }
    });
    console.log(origins)
    console.log(destinations)
  }
  const loadingComponent = <IonItem>
    <IonLabel>Estimating Price</IonLabel>
    <IonSpinner name="lines-small"></IonSpinner>
  </IonItem>;

  return (
    <>
      <IonItem color="light">
        <IonLabel>Order Details</IonLabel>
      </IonItem>
      <BookingContactPicker customer={customer} setCustomer={setCustomer}/>
      <IonItem className={`${false && 'ion-invalid'}`}>
        <IonIcon icon={calendar} slot="start" />
        <IonLabel color="medium">Pickup Time</IonLabel>
        <IonDatetimeButton slot='end' datetime="datetime"></IonDatetimeButton>

        {false && <IonNote slot='error'>Error</IonNote>}
      </IonItem>
      <IonItem color="light">
        <IonLabel>Location Schedules</IonLabel>
      </IonItem>
      <IonModal keepContentsMounted={true}>
        <IonDatetime ref={dateTimeRef} min={new Date().toISOString()} id="datetime"></IonDatetime>
      </IonModal>
      {stopPoints.map((stopPoint: BookingPoint, index: number) => {
        let startIcon, addBtn, removeBtn, placeholder, title, flightInfoEnabled
        if (index === 0) {
          startIcon = play
          addBtn = true
          removeBtn = false
          placeholder = "Enter Pickup Point"
          title = "Pickup Point Details"
          flightInfoEnabled = true
        } else if (index === (stopPoints.length - 1)) {
          startIcon = stopCircle
          addBtn = removeBtn = false
          placeholder = "Enter Dropoff Point"
          title = "Dropoff Point Details"
          flightInfoEnabled = true
        } else {
          startIcon = location
          addBtn = false
          removeBtn = true
          placeholder = "Enter Stop Address #" + (index)
          title = 'Stop Point Details #' + (index)
          flightInfoEnabled = false
        }

        return <IonItem key={index}>
          <IonIcon icon={startIcon} slot="start" />
          <AutoCompleteModal
            currentPoint={stopPoint}
            placeholder={placeholder}
            title={title}
            flightInfoEnabled={flightInfoEnabled}
            onUpdate={(newDate: BookingPoint) => updateStopPointField(index, newDate)} />
          {addBtn && stopPoints.length < 11 && <IonButton onClick={addStopPointField} fill='clear' color="dark" slot='end'><IonIcon icon={addCircle} /></IonButton>}
          {removeBtn && <IonButton onClick={() => removeStopPointField(index)} fill='clear' slot='end'><IonIcon icon={trash} /></IonButton>}
        </IonItem>
      })}
      <IonItem color="light">
        <IonLabel>Vehicle Information</IonLabel>
      </IonItem>
      <IonItem>
        <IonIcon icon={carSport} slot="start" />
        <IonLabel>Number Of Car(s) Required</IonLabel>
        <IonSelect slot='end' interface="action-sheet" value={priceing.carCount} onIonChange={(e) => { setPriceing({ ...priceing, ...{ carCount: parseInt(e.target.value) } }) }}>
          {[...Array(10)].map((value, index) => (<IonSelectOption key={index} value={index + 1}>{index + 1}</IonSelectOption>))}
        </IonSelect>
      </IonItem>
      <IonItem>
        <IonIcon icon={pencil} slot="start" />
        <IonLabel position="floating">Note To Driver</IonLabel>
        <IonTextarea rows={4} />
      </IonItem>
      {checkoutEnabled && priceing.loading ? loadingComponent : <div className='details'>
        {priceing.formatedTime && priceing.formatedTime.length === 2 && <div className='item'>
          <h1>{priceing.formatedTime[0]}</h1>
          <h1>{priceing.formatedTime[1]}</h1>
        </div>}
        {priceing.formatedDistance && priceing.formatedDistance.length === 2 && <div className='item'>
          <h1>{priceing.formatedDistance[0]}</h1>
          <h1>{priceing.formatedDistance[1]}</h1>
        </div>}
        {priceing.cost && priceing.carCount && <div className='item'>
          <h1>£{(priceing.cost * priceing.carCount).toFixed(2)}</h1>
        </div>}
      </div>}
    </>
  );
};

export default NewTripForm;
