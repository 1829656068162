import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonText } from '@ionic/react';
import { baseUrl } from '../../configs/app';
import SimpleProduct from '../../types/product.type';
import './ProductListItem.scss'
import { eye } from 'ionicons/icons';
type Props = {
    product: SimpleProduct
}
const ProductListItem: React.FC<Props> = ({ product }) => {
    return (
        <IonItem className='product'>
            <div className='thumb-containter' slot='start'>
                <img src={baseUrl + product.image} alt="" />
                <IonButton fill='clear'>
                    <IonIcon icon={eye} />
                </IonButton>
            </div>
            <div className='name'>
                <IonText>{product.name}</IonText>
            </div>
            <div className='pricing'>
                <IonText className='regular'>{product.regular_price} </IonText>
                <IonText className='sale' color='primary'>{product.sale_price}/=</IonText>
            </div>
        </IonItem>
    );
};

export default ProductListItem;
